<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar title="订单详情" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div class="tophes">
      <div class="heds" v-if="list.type != 1">
        <div style="padding:0 20px">
          <div>{{list.companyName}}</div>
          <div class="dizhi">地址：{{list.address}}</div>
        </div>
        <div style="margin-top: 25px;">
          <a :href="'tel:'+list.cphone">
            <van-button
              icon="phone"
              class="bots"
              style="border-right: 1px solid #dcdcdc"
              type="default"
            >拨打电话</van-button>
          </a>
          <van-button icon="location" class="bots" type="default">导航</van-button>
        </div>
      </div>
      <div class="mian">
        <div class="mian_a">
          <div class="xinxi_A">
            <span class="xin_b">订单类型：</span>
            <span class="xinxi_b">
              <span v-if="list.type == 0">上线验车</span>
              <span v-else>免检领标</span>
            </span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">车牌号码：</span>
            <span class="xinxi_b">{{list.carNo}}</span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">车辆类型：</span>
            <span class="xinxi_b">{{list.carModel}}</span>
          </div>
          <div class="xinxi_A" v-if="list.type == 0">
            <span class="xin_b">预约时间：</span>
            <span class="xinxi_b">{{list.appointDate}}</span>
          </div>
          <div class="xinxi_A" v-if="list.type == 1">
            <span class="xin_b">下单时间：</span>
            <span class="xinxi_b">{{list.createdDate}}</span>
          </div>
          <div class="xinxi_A" v-if="list.type == 0">
            <span class="xin_b">联系信息：</span>
            <span class="xinxi_b">{{list.contactPerson}}</span>
          </div>
          <div class="xinxi_A" v-if="list.type == 1">
            <span class="xin_b">收件信息：</span>
            <span class="xinxi_b">{{list.contactPerson}}/{{list.phone}}/{{list.consigneeAddress}}</span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">费用合计：</span>
            <span class="xinxi_b">
              ￥{{list.fee}}元
              <span v-if="list.type == 0">（验车：￥{{list.fee}}元）</span>
            </span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">备注说明：</span>
            <span class="xinxi_b"></span>
          </div>
          <van-button
            color="#0d906e"
            round
            class="clsyuyue"
            size="small"
            @click="tozhifu"
            v-preventReClick
            v-if="list.status == 0"
          >立即支付</van-button>
          <img v-else class="imgzf" src="../../assets/img/yizhifu.png" alt="">
        </div>
        <div class="tishi" v-if="list.type == 0">需携带行驶证原件、车辆无未备案的改装、无未处理的违章记录</div>
        <div class="tishi" v-if="list.type == 1" style="text-align: center;">需提供行驶证照片、车辆无未处理的违章记录</div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import { cardetails, carpay } from "../../api/check.js";
import tabbarcar from "../../components/tabbarcar.vue";
import preventReClick from "../../assets/preventReClick";
export default {
  components: {
    tabbarcar
  },
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      id: this.$route.query.id,
      actv: 1,
      list: {},
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    tozhifu() {
      var _this = this;
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        id: this.list.id
      };
      carpay(data)
        .then(res => {
          if (res.code == 200) {

            loading.clear();
            if (typeof WeixinJSBridge === "undefined") {
              // 微信浏览器内置对象。参考微信官方文档
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
              }
            } else {
              console.log("准备调用微信支付");
              _this.onBridgeReady(res.data);
            }
          }
        })
        .catch(() => loading.clear());
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast.success("支付成功");
            // setTimeout(() => {
              vm.onLoad()
              //   vm.$router.go(-1);
            // }, 900);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        id: this.id
      };
      cardetails(data)
        .then(e => {
          loading.clear();
          e.data.phone =
            e.data.phone.substr(0, 3) + "****" + e.data.phone.substr(7);
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      // this.page++;
      this.onLoad();
    },

    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "inspect_car"
      // });
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.tophes {
  padding-top: 50px;
}
.heds {
  margin: 10px 15px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 10px 0px 0 0px;
  font-size: 15px;
  overflow: hidden;
}
.dizhi {
  color: #666;
}
.bots {
  width: 50%;
  border: none;
  font-size: 15px;
  background: #f5f5f5;
}
.mian {
  overflow: hidden;
  margin: 10px 15px;
  background-image: linear-gradient(180deg, #1ec3a7, #0d906e);
  border-radius: 10px;
  padding: 5px 0 15px 0;
}
.mian_a {
  margin: 20px 20px 10px 20px;
  background: #fff;
  font-size: 14px;
  padding: 20px 10px;
  border-radius: 10px;
}
.xinxi_A {
  overflow: hidden;
  padding: 4px 0;
  line-height: 21px;
}
.xinxi_b {
  color: #000;
  float: left;
  width: 70%;
}
.xin_b {
  float: left;
  width: 80px;
  text-align: right;
}
.clsyuyue {
  display: block;
  width: 150px;
  margin: auto;
  margin-top: 30px;
  font-size: 15px;
}
.tishi {
  font-size: 14px;
  color: #fff;
  padding: 0px 20px;
  line-height: 22px;
}
.imgzf{
  display: block;
  margin: auto;
}
</style>